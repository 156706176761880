import Vue from 'vue';
import VueI18n from 'vue-i18n';
import uz from './locales/uz.json'
import ru from './locales/ru.json'
import en from './locales/en.json'

Vue.use(VueI18n);

let languageSelected = localStorage.getItem('x_lang');
if (!languageSelected) {
  // languageSelected = 'ru';
  languageSelected = 'uz';
}

const i18n = new VueI18n({
  locale: languageSelected,
  // fallbackLocale: 'ru',
  fallbackLocale: 'uz',
  // messages: {ru}
  messages: {uz, ru, en}
})

export default i18n;