import Vue from 'vue';
import Vuex from 'vuex';
import { Message } from 'element-ui';

export const SET_MFY_DATA = "SET_MFY_DATA";
export const SET_MFY = "SET_MFY";
export const SET_MFY_DATA_LOADING = "SET_MFY_DATA_LOADING";
export const SET_MFY_ID = "SET_MFY_ID";
export const SET_MFY_POLYGON_DATA = "SET_MFY_POLYGON_DATA";
export const SET_HOMES_NUMBER_DATA = "SET_HOMES_NUMBER_DATA";
export const SET_REAL_ESTATE_OBJECT_DATA = "SET_REAL_ESTATE_OBJECT_DATA";
export const SET_WITH_BORDER_LINE = "SET_WITH_BORDER_LINE";

Vue.use(Vuex);

const mfyStore = {
    state: {
        mfyData: [],
        mfy: null,
        mfyDataLoading: false,
        withBorderLine: false,
        mfyPolygonData: [],
        mfyId: null,
        homesNumberData: [],
        realEstateObjectData: []
    },
    mutations: {
        [SET_MFY_DATA](state, mfyData) {
            state.mfyData = mfyData
        },
        [SET_MFY](state, mfy) {
            state.mfy = mfy
        },
        [SET_MFY_DATA_LOADING](state, mfyDataLoading) {
            state.mfyDataLoading = mfyDataLoading
        },
        [SET_MFY_ID](state, mfyId) {
            state.mfyId = mfyId
        },
        [SET_MFY_POLYGON_DATA](state, mfyPolygonData) {
            state.mfyPolygonData = mfyPolygonData
        },
        [SET_HOMES_NUMBER_DATA](state, homesNumberData) {
            state.homesNumberData = homesNumberData
        },
        [SET_REAL_ESTATE_OBJECT_DATA](state, realEstateObjectData) {
            state.realEstateObjectData = realEstateObjectData
        },
        [SET_WITH_BORDER_LINE](state, withBorderLine) {
            state.withBorderLine = withBorderLine
        }
    },
    actions: {
        getMFYData({commit}, regionId) {
            commit(SET_MFY_DATA_LOADING, true)
            Vue.prototype.$axios.get(`mfy/select/${regionId}`)
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_MFY_DATA, res.data.data)
                        commit(SET_MFY_DATA_LOADING, false)
                    }
                }, err => {
                    console.log(err);
                    commit(SET_MFY_DATA_LOADING, false)
                });
        },
        setMFYId({commit, dispatch, state}, {mfyId, isModeSimple}) {
            commit(SET_MFY_ID, mfyId)
            if(!isModeSimple) {
                if (mfyId) {
                    if (state.withBorderLine){
                        dispatch("getPolygonByMFYIdWithBorderLine", mfyId)
                    } else {
                        dispatch("getPolygonByMFYId", mfyId)
                    }
                }
                else {
                    dispatch("resetRoadAllFields")
                }
            }

        },
        getPolygonByMFYId({state,commit, dispatch}, mfyId) {
            Vue.prototype.$axios.get(`mfy/geom/${mfyId}`)
                .then(res => {
                    if (res && res.data && res.data.data && res.data.data.area) {
                        dispatch("getAllObjectPolygonsByMfyId", {mfyId, mfyData: [res.data.data.area]})
                    } else {
                        commit(SET_MFY_POLYGON_DATA, [])
                    }
                }, err => {
                    console.log(err);
                });
        },
        getPolygonByMFYIdWithBorderLine({state,commit, dispatch}, mfyId) {
            Vue.prototype.$axios.get(`mfy/geom/border-line/${mfyId}`)
                .then(res => {
                    if (res && res.data) {
                        dispatch("getAllObjectPolygonsByMfyId", {mfyId, mfyData: res.data})
                    } else {
                        commit(SET_MFY_POLYGON_DATA, [])
                    }
                }, err => {
                    console.log(err);
                });
        },
        setHomeNumbersByMFYId({commit}, mfyId) {
            if (mfyId) {
                let message = Message.info({
                    message: 'Уйлар рақамлари юкланмоқда!',
                    showClose: false,
                    duration: 0,
                    customClass: "homes-number-loader-message"
                })
                Vue.prototype.$axios.post('real-estate/mfy/objects', {
                    mfyId: mfyId
                })
                    .then(res => {
                        // console.log("getHomeNumbersByMFYId res = ", res)
                        if (res && res.data && res.data.data) {
                            commit(SET_HOMES_NUMBER_DATA, res.data.data)
                        } else {
                            commit(SET_HOMES_NUMBER_DATA, [])
                        }
                        message.close()
                    }, err => {
                        commit(SET_HOMES_NUMBER_DATA, [])
                        message.close()
                        console.log(err);
                    });
            } else {
                commit(SET_HOMES_NUMBER_DATA, [])
            }
        },
        resetMFYData({commit}) {
            commit(SET_MFY_DATA, [])
            commit(SET_MFY, null)
            commit(SET_MFY_ID, null)
        },
        getAllObjectPolygonsByMfyId({commit}, {mfyId, mfyData}) {
            Vue.prototype.$axios.get(`real-estate/all-polygons/${mfyId}`)
                .then(res => {
                    if (res && res.data) {
                        commit(SET_REAL_ESTATE_OBJECT_DATA, res.data)
                    }
                    else {
                        commit(SET_REAL_ESTATE_OBJECT_DATA, [])
                    }
                    commit(SET_MFY_POLYGON_DATA, mfyData)
                }, err => {
                    console.log(err);
                    commit(SET_MFY_POLYGON_DATA, mfyData)
                });
        },
        getMfyWithBorderline({commit, state, dispatch}, withBorderline) {
            commit(SET_WITH_BORDER_LINE, withBorderline)
            if (state.mfyId){
                if (withBorderline){
                    dispatch("getPolygonByMFYIdWithBorderLine", state.mfyId)
                } else {
                    dispatch("getPolygonByMFYId", state.mfyId)
                }
            }
        },
        resetMFYAllFields({commit}) {
            commit(SET_MFY_DATA, [])
            commit(SET_MFY, null)
            commit(SET_MFY_ID, null)
            commit(SET_MFY_POLYGON_DATA, [])
            commit(SET_REAL_ESTATE_OBJECT_DATA, [])
        }
    },
    getters: {
        mfyDataList: state => {
            return state.mfyData
        }
    }
};

export default mfyStore;