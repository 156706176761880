import Vue from 'vue';
import Vuex from 'vuex';
import {GLOBAL_LOADING} from "@/store/store";
import {warningMessage} from "@/shared/statusMessages";

Vue.use(Vuex);

const SET_AREAS_BY_CADASTRE = "SET_AREAS_BY_CADASTRE";
const SET_WITH_REAL_ESTATE_OBJECTS_BY_CADASTRE = "SET_WITH_REAL_ESTATE_OBJECTS_BY_CADASTRE"

const cadastreStore = {
    // namespace: true,
    state: {
        cadastreCode: null,
        cadastreAreas: null,
        cadastreRealEstateObjects: null
    },
    mutations: {
        [SET_AREAS_BY_CADASTRE](state, cadastreAreas) {
            state.cadastreAreas = cadastreAreas
        },
        [SET_WITH_REAL_ESTATE_OBJECTS_BY_CADASTRE](state, cadastreRealEstateObjects) {
            state.cadastreRealEstateObjects = cadastreRealEstateObjects
        },
    },
    actions: {
        getAreasByCadastre({commit}, cadastreInput) {
            commit(GLOBAL_LOADING, true)
            Vue.prototype.$axios.post("mavze/search", {
                    uuid: null,
                    action: null,
                    search: cadastreInput,
                    page: {
                        page: 1,
                        size: 10,
                        sort: {
                            col: null,
                            dir: null
                        }
                    },
                    roles: [],
                    parentId: null,
                    lan: null
                })
                .then(res => {
                    // console.log("res = ", res)
                    if (res && res.data && res.data.data && res.data.data.geom) {
                        commit(SET_AREAS_BY_CADASTRE, res.data.data)
                    }
                    else {
                        warningMessage({onlyMessageText: 'Ушбу кадастр рақамига оид маълумотлар топилмади!'}, 8000)
                    }
                    commit(GLOBAL_LOADING, false)
                }, err => {
                    console.log(err);
                    commit(GLOBAL_LOADING, false)
                });
        },
        getRealEstateObjectsByCadastre({commit}, cadastreInput) {
            commit(GLOBAL_LOADING, true)
            Vue.prototype.$axios.post("real-estate/cadastre-number", {
                search: cadastreInput
            })
                .then(res => {
                    if (res && res.data && res.data.data ) {
                        commit(SET_WITH_REAL_ESTATE_OBJECTS_BY_CADASTRE, res.data.data)
                    }
                    else {
                        warningMessage({onlyMessageText: 'Ушбу кадастр рақамига оид мулк маълумотлари топилмади!'}, 10000)
                    }
                    commit(GLOBAL_LOADING, false)
                }, err => {
                    console.log(err);
                    commit(GLOBAL_LOADING, false)
                });
        },
        removeAreasByCadastre({commit}) {
            commit(SET_AREAS_BY_CADASTRE, {})
            commit(SET_WITH_REAL_ESTATE_OBJECTS_BY_CADASTRE, {})
        }
    },
    getters: {

    }
};

export default cadastreStore;