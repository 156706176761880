<template>

  <div class="login-page with-max-width p-5 flex items-center justify-center min-h-screen">

    <div class="login-page-body">

      <el-card class="w-[400px] !shadow-[0_0_15px_rgba(180,180,180,1)]">
        <div class="card-header">
          <div class="card-title font-medium text-xl text-center">Login</div>
        </div>
        <div class="card-body">
          <el-row>
            <el-col class="form-field">
              <label>{{$t('common.fields.userName')}}</label>
              <el-input
                  type="email"
                  placeholder=""
                  v-model="user.username"
                  @keyup="logInReset"
                  :class="{'is-error': $v.user.username.$error}"
                  clearable
                  @blur="$v.user.username.$touch()"
              />
              <error-message :field-model="$v.user.username"/>
            </el-col>
            <el-col class="form-field">
              <label>{{$t('common.fields.password')}}</label>
              <el-input
                  type="password"
                  placeholder=""
                  v-model="user.password"
                  @keyup="logInReset"
                  :class="{'is-error': $v.user.password.$error}"
                  clearable
                  @blur="$v.user.password.$touch()"
              />
              <error-message :field-model="$v.user.password"/>
            </el-col>
          </el-row>
        </div>
        <div class="card-footer mt-4">
          <el-button type="primary" @click="logIn" class="mx-auto" native-type="submit">Login</el-button>
        </div>
      </el-card>

    </div>

  </div>

</template>

<script>

import {mapState} from 'vuex';
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {warningMessage} from "@/shared/statusMessages";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      user: {
        username: null,
        password: null
      }
    }
  },
  computed: {
    ...mapState(['loginProgress', 'loginError'])
  },
  validations: {
    user: {
      username: {
        required: required,
        minLength: minLength(5),
        maxLength: maxLength(20)
      },
      password: {
        required: required,
        minLength: minLength(6),
        maxLength: maxLength(10)
      }
    }
  },
  methods: {
    logIn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        warningMessage({key: 'fillRequiredFields'})
        return false;
      }
      this.$store.dispatch('logIn', this.user);
    },
    logInReset() {
      this.$store.dispatch('logInReset');
    }
  }
}
</script>

<style scoped>

</style>