<template>
  <div class="global-loader-wrapper" v-show="show">
    <div id="global-loader-target" class="global-loader-target"/>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "GlobalLoaderIndicator",
  data() {
    return {
      loader: null,
      show: false,
    }
  },
  computed: {
    ...mapState(["isGlobalLoading"])
  },
  methods: {
    startLoading() {
      this.loader = this.$loading({
        fullscreen: true,
        lock: true,
        target: "#global-loader-target",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    endLoading() {
      this.loader && this.loader.close();
    }
  },
  watch: {
    isGlobalLoading: function(val) {
      if(val) {
        this.show = true
        this.startLoading()
      }
      else {
        this.show = false
        this.endLoading();
      }
    }
  },
  destroyed() {
    this.endLoading();
  }
}
</script>

<style scoped>

</style>