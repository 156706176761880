import Vue from 'vue';
import Vuex from 'vuex';

export const SET_MASSIV_DATA = "SET_MASSIV_DATA";
export const SET_MASSIV = "SET_MASSIV";
export const SET_MASSIV_DATA_LOADING = "SET_MASSIV_DATA_LOADING";
export const SET_MASSIV_ID = "SET_MASSIV_ID";
export const SET_MASSIV_POLYGON_DATA = "SET_MASSIV_POLYGON_DATA";

Vue.use(Vuex);

const massivStore = {
    state: {
        massivData: [],
        massiv: null,
        massivDataLoading: false,
        massivPolygonData: {},
        massivId: null
    },
    mutations: {
        [SET_MASSIV_DATA](state, massivData) {
            state.massivData = massivData
        },
        [SET_MASSIV](state, massiv) {
            state.massiv = massiv
        },
        [SET_MASSIV_DATA_LOADING](state, massivDataLoading) {
            state.massivDataLoading = massivDataLoading
        },
        [SET_MASSIV_ID](state, massivId) {
            state.massivId = massivId
        },
        [SET_MASSIV_POLYGON_DATA](state, massivPolygonData) {
            state.massivPolygonData = massivPolygonData
        }
    },
    actions: {
        getMassivData({commit}, zoneId) {
            commit(SET_MASSIV_DATA_LOADING, true)
            Vue.prototype.$axios.get(`massiv/select_zona/${zoneId}`)
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_MASSIV_DATA, res.data.data)
                        commit(SET_MASSIV_DATA_LOADING, false)
                    }
                }, err => {
                    console.log(err);
                    commit(SET_MASSIV_DATA_LOADING, false)
                });
        },
        setMassivId({commit, dispatch}, massivId) {
            commit(SET_MASSIV_ID, massivId)
            if (massivId) {
                dispatch("getPolygonByMassivId", massivId)
            }
        },
        getPolygonByMassivId({commit}, massivId) {
            Vue.prototype.$axios.get(`massiv/${massivId}`)
                .then(res => {
                    if (res && res.data && res.data.area) {
                        commit(SET_MASSIV_POLYGON_DATA, res.data.area)
                    }
                    else {
                        commit(SET_MASSIV_POLYGON_DATA, {})
                    }
                }, err => {
                    console.log(err);
                });
        },
        resetMassivData({commit}) {
            commit(SET_MASSIV_DATA, [])
        },
        resetMassivAllFields({commit}) {
            commit(SET_MASSIV_DATA, [])
            commit(SET_MASSIV, null)
            commit(SET_MASSIV_ID, null)
            commit(SET_MASSIV_POLYGON_DATA, {})
        }
    },
    getters: {
        massivDataList: state => {
            return state.massivData
        }
    }
};

export default massivStore;