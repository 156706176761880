import Vue from 'vue';
import Vuex from 'vuex';

const SET_MAP_TYPES = "SET_MAP_TYPES";
const SET_SELECTED_MAP_TYPES = "SET_SELECTED_MAP_TYPES";
const SET_SELECTED_LAND_USE_MAP_ITEMS= "SET_SELECTED_LAND_USE_MAP_ITEMS"
const SET_SELECTED_TRANSPORT_MAP_ITEMS= "SET_SELECTED_TRANSPORT_MAP_ITEMS"
const SET_SELECTED_TRAFFIC_MAP_ITEMS= "SET_SELECTED_TRAFFIC_MAP_ITEMS"
const SET_SELECTED_WATER_MAP_ITEMS= "SET_SELECTED_WATER_MAP_ITEMS"
const SET_SELECTED_BUILDING_MAP_ITEMS= "SET_SELECTED_BUILDING_MAP_ITEMS"

Vue.use(Vuex);

const mapTypeStore = {
    state: {
        mapTypes: null,
        selectedMapTypes: [],
        selectedLandUseMapItems: [],
        selectedTransportMapItems: [],
        selectedTrafficMapItems: [],
        selectedWaterMapItems: [],
        selectedBuildingMapItems: []
    },
    mutations: {
        [SET_MAP_TYPES](state, mapTypes) {
            state.mapTypes = mapTypes
        },
        [SET_SELECTED_MAP_TYPES](state, selectedMapTypes) {
            state.selectedMapTypes = selectedMapTypes
        },
        [SET_SELECTED_LAND_USE_MAP_ITEMS](state, selectedLandUseMapItems) {
            state.selectedLandUseMapItems = selectedLandUseMapItems
        },
        [SET_SELECTED_TRANSPORT_MAP_ITEMS](state, selectedTransportMapItems) {
            state.selectedTransportMapItems = selectedTransportMapItems
        },
        [SET_SELECTED_TRAFFIC_MAP_ITEMS](state, selectedTrafficMapItems) {
            state.selectedTrafficMapItems = selectedTrafficMapItems
        },
        [SET_SELECTED_WATER_MAP_ITEMS](state, selectedWaterMapItems) {
            state.selectedWaterMapItems = selectedWaterMapItems
        },
        [SET_SELECTED_BUILDING_MAP_ITEMS](state, selectedBuildingMapItems) {
            state.selectedBuildingMapItems = selectedBuildingMapItems
        },
    },
    actions: {
        getMapTypes({commit, dispatch}) {
            Vue.prototype.$axios.get('map/tables')
                .then(res => {
                    if (res && res.data) {
                        commit(SET_MAP_TYPES, res.data)
                    }
                }, err => {
                    console.log(err);
                });
        },
        setSelectedMapType({commit, state, dispatch}, selectedMapType) {
            let selectedMapTypes = []
            if(state.selectedMapTypes.includes(selectedMapType)) {
                selectedMapTypes = [...state.selectedMapTypes].filter(item => item !== selectedMapType)
                dispatch("setSelectedMapTypeItems", {type: selectedMapType, values: []})
            }
            else {
                selectedMapTypes = [...state.selectedMapTypes, selectedMapType]
            }
            commit(SET_SELECTED_MAP_TYPES, selectedMapTypes)
        },
        setSelectedMapTypeItems({commit, state}, options) {
            if(options && options.type)
            switch (options.type) {
                case "BUILDING": {
                    commit(SET_SELECTED_BUILDING_MAP_ITEMS, options.values)
                    break
                }
                case "LAND_USE": {
                    commit(SET_SELECTED_LAND_USE_MAP_ITEMS, options.values)
                    break
                }
                case "WATER": {
                    commit(SET_SELECTED_WATER_MAP_ITEMS, options.values)
                    break
                }
                case "TRANSPORT": {
                    commit(SET_SELECTED_TRANSPORT_MAP_ITEMS, options.values)
                    break
                }
                case "TRAFFIC": {
                    commit(SET_SELECTED_TRAFFIC_MAP_ITEMS, options.values)
                    break
                }
            }
        },
        resetAllMapTypeWithItems({commit}) {
            commit(SET_SELECTED_MAP_TYPES, [])
            commit(SET_SELECTED_LAND_USE_MAP_ITEMS, [])
            commit(SET_SELECTED_TRANSPORT_MAP_ITEMS, [])
            commit(SET_SELECTED_TRAFFIC_MAP_ITEMS, [])
            commit(SET_SELECTED_WATER_MAP_ITEMS, [])
            commit(SET_SELECTED_BUILDING_MAP_ITEMS, [])
        }
    },
    getters: {
    }
};

export default mapTypeStore;