import Vue from 'vue';
import Vuex from 'vuex';
import Keycloak from "keycloak-js";
import App from "../App.vue";
import router from "../router";
import i18n from "@/i18n";
import storeItems from "@/store/items";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_RESET = "LOGIN_RESET";
export const PROFILE = "PROFILE";
export const LOGOUT = "LOGOUT";
export const MAIN_LOADING = "MAIN_LOADING";
export const GLOBAL_LOADING = "GLOBAL_LOADING";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const SIDEBAR_TOGGLE_MINI = "SIDEBAR_TOGGLE_MINI";
export const SIDEBAR_TOGGLE_MOBILE = "SIDEBAR_TOGGLE_MOBILE";
export const DEFAULT_PAGE = 'DEFAULT_PAGE';
export const SIDEBAR_TOGGLE_SETTINGS_MENU = 'SIDEBAR_TOGGLE_SETTINGS_MENU';
export const KEYCLOAK = "KEYCLOAK";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        profile: {},
        isAdmin: false,
        isRoot: false,
        isGlobalLoading: false,
        mainLoading: false,
        isLoggedIn: false,
        loginProgress: false,
        loginError: '',
        sidebar: {
            open: true,
            mini: true,
            mobile: false,
            isSettingsMenuOpen: false
        },
        isDefaultPage: false,
        keycloak: new Keycloak({
            url: process.env.VUE_APP_KEYCLOAK_API_URL + '/auth',
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
            onLoad: 'login-required'
        }),
        page: 1,
        ...storeItems.state
    },
    mutations: {
        [LOGIN](state) {
            state.loginProgress = true;
        },
        [LOGIN_SUCCESS](state) {
            state.loginProgress = false;
            state.isLoggedIn = true;
        },
        [LOGIN_FAILURE](state) {
            state.loginProgress = false;
            state.isLoggedIn = false;
            state.loginError = 'Имя пользователя и / или пароль недействительны';
        },
        [LOGIN_RESET](state) {
            state.loginError = '';
        },
        [LOGOUT](state) {
            state.isLoggedIn = false;
            state.profile = {};
            state.isAdmin = false;
        },
        [PROFILE](state, payload) {
            state.isLoggedIn = true;
            state.profile = payload;
            state.isAdmin = false;
            if (state.profile && state.profile.roles && state.profile.roles.length > 0) {
                for (const role of state.profile.roles) {
                    if (role === 'ROLE_ADMIN') {
                        state.isAdmin = true;
                    }
                }
            }
        },
        [MAIN_LOADING](state, loading) {
            state.mainLoading = loading;
        },
        [GLOBAL_LOADING](state, loading) {
            state.isGlobalLoading = loading;
        },
        [SIDEBAR_TOGGLE](state) {
            state.sidebar.open = !state.sidebar.open;
        },
        [SIDEBAR_TOGGLE_MINI](state) {
            state.sidebar.mini = !state.sidebar.mini;
        },
        [SIDEBAR_TOGGLE_MOBILE](state) {
            state.sidebar.mobile = !state.sidebar.mobile;
        },
        [SIDEBAR_TOGGLE_SETTINGS_MENU](state) {
            state.sidebar.isSettingsMenuOpen = !state.sidebar.isSettingsMenuOpen;
        },
        [DEFAULT_PAGE](state,pageData) {
            state.isDefaultPage = pageData.isPage;
            state.page = pageData.page
        },
        [KEYCLOAK](state) {
            state.keycloak.init({
                onLoad: 'login-required'
            }).then((auth) => {
                // console.log("auth = ", auth)
                if (!auth){
                    window.location.reload()
                } else {
                    // console.log("auth = ", auth)
                    // console.log("keycloak = ", state.keycloak)
                    /*new Vue({
                        render: h =>h(App, {props: {keycloak: keycloak}}),
                        router,
                        store
                    }).$mount('#app');*/
                    new Vue({
                        router,
                        store,
                        i18n,
                        render: h => h(App, {props: {keycloak: state.keycloak}}),
                    }).$mount('#app')
                    localStorage.setItem("x_token", state.keycloak.token)
                    localStorage.setItem("x_refresh_token", state.keycloak.refreshToken)
                }

                // Token Refresh
                setInterval(() => {
                    store.dispatch("logOut", state.keycloak)
                }, 12 * 3600 * 1000)

            }).catch(error => {
                // console.error("Authenticated Failed");
                console.log("error = ", error)
            });
        },
        ...storeItems.mutations
    },
    actions: {
        keycloak({commit}) {
            commit(KEYCLOAK);
        },
        logIn({commit/*, dispatch*/}/*, creds*/) {
            // commit(LOGIN); // show spinner
            /*return new Promise(resolve => {
                VueApp.$http.post('login', creds)
                    .then(res => {
                        this.loading = false;
                        res = res.data;
                        if (res.status && res.status === 'SUCCESS') {
                            localStorage.setItem('x_token', res.data.token);
                            commit(LOGIN_SUCCESS);
                            VueApp.$router.push('/category');
                            dispatch('initProfile', false);
                        } else {
                            commit(LOGIN_FAILURE);
                        }
                        resolve();
                    }, err => {
                        console.log(err);
                        commit(LOGIN_FAILURE);
                        resolve();
                    });
            });*/
            // commit(LOGIN_SUCCESS);
        },
        logInReset({commit}) {
            commit(LOGIN_RESET);
        },
        logOut({commit}, keycloak) {
            localStorage.removeItem("x_token");
            keycloak.logout();
            commit(LOGOUT);
        },
        initProfile({commit}, keycloak) {
            // if (keycloak && keycloak.token && keycloak.token.length > 0) {
                // if (this.state.isAdmin || this.state.isRoot){
                // Vue.prototype.$axios.get('profile')
                //     .then(res => {
                //         res = res.data
                //         console.log("res.data = ", res.data)
                //         if (res.id && res.id > 0) {
                //             if (!res.externalUser){
                //                 commit(PROFILE, res);
                //             } else {
                //                 alert("Sizda ruhsat yo'q");
                //             }
                //         }
                //         commit(MAIN_LOADING, false);
                //     }, err => {
                //         console.log(err);
                //     });
                // }
            // }
            // else {
            //     commit(MAIN_LOADING, false);
            // }
            commit(PROFILE, {
                name: "Ansoriddin",
                roles: [{
                    role: "Admin"
                }]
            });

            commit(MAIN_LOADING, false);

        },
        sideBarToggle({commit}) {
            commit(SIDEBAR_TOGGLE);
        },
        sideBarToggleMini({commit}) {
            commit(SIDEBAR_TOGGLE_MINI);
        },
        sideBarToggleMobile({commit}) {
            commit(SIDEBAR_TOGGLE_MOBILE);
        },
        sideBarToggleSettingsMenu({commit}) {
            commit(SIDEBAR_TOGGLE_SETTINGS_MENU);
        },
        defaultPage({commit}, pageData) {
            commit(DEFAULT_PAGE,pageData)
        },
        setGlobalLoading({commit}, loading) {
            commit(GLOBAL_LOADING, !!loading)
        },
        ...storeItems.actions
    },
    getters: {
        isDefaultPage: state => {
            return state.isDefaultPage
        },
        getPage: state => {
            return state.page
        },
        getState: state => {
            return state
        },
        isRoot: state => {
            return state.isRoot
        },
        isAdmin: state => {
            return state.isAdmin
        },
        ...storeItems.getters
    }

});

export default store;