import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const SET_WITH_REAL_ESTATE_OBJECTS = "SET_WITH_REAL_ESTATE_OBJECTS"
const SET_WITH_HOME_NUMBERS = "SET_WITH_HOME_NUMBERS"

const additionalCheckboxStore = {
    state: {
        withRealEstateObjects: false,
        withHomesNumber: false
    },
    mutations: {
        [SET_WITH_REAL_ESTATE_OBJECTS](state, withRealEstateObjects) {
            state.withRealEstateObjects = withRealEstateObjects
        },
        [SET_WITH_HOME_NUMBERS](state, withHomesNumber) {
            state.withHomesNumber = withHomesNumber
        },
    },
    actions: {
        setWithRealEstateObjects({commit}, withRealEstateObjects) {
            commit(SET_WITH_REAL_ESTATE_OBJECTS, withRealEstateObjects)
        },
        setWithHomesNumber({commit}, withHomesNumber) {
            commit(SET_WITH_HOME_NUMBERS, withHomesNumber)
        }
    },
    getters: {

    }
};

export default additionalCheckboxStore;