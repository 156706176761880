<template>
  <div class="flex items-center justify-center my-5">
    <el-pagination
      background
      :layout="layout"
      :total="total"
      :page-size.sync="size"
      :current-page="currentPage"
      @current-change="change"
    >
      <slot/>
    </el-pagination>
  </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
          layout: {
            type: String,
            default: 'prev, pager, next'
          },
          total: {
            type: Number,
            default: 1
          },
          size: {
            type: Number,
            default: 1
          },
          currentPage: {
            type: Number,
            default: 1
          },
          change: {
            type: Function,
            default: () => {}
          }
        },
        data() {
            return {
                pages: []
            }
        },
        methods: {
        },
        created() {
        }
    }
</script>
