import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const SET_NEW_DRAWER = "SET_NEW_DRAWER";
export const SET_TOGGLE_DRAWER = "SET_TOGGLE_DRAWER";

const drawerStore = {
    state: {
        drawer: {
            bindMFYIdToHome: false,
            cadastreNumbers: false,
            category: false,
            realEstateObject: false,
            requestFixErrorRealEstate: false,
            mfySettings: false
        }
    },
    mutations: {
        [SET_NEW_DRAWER](state, newDrawer){
            if(newDrawer.name && !state.drawer.hasOwnProperty(newDrawer.name)) {
                state.drawer[newDrawer.name] = newDrawer
            }
        },
        [SET_TOGGLE_DRAWER](state, {name, show}) {
            if(state.drawer.hasOwnProperty(name)) {
                state.drawer[name] = show
            }
        }
    },
    actions: {
        toggleDrawer({commit}, {name, show}) {
            commit(SET_TOGGLE_DRAWER, {name, show})
        }
    },
    getters: {
    }
};

export default drawerStore;