import Vue from 'vue'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import axiosHttp from './axios'
import store from './store/store'
import vue2Dropzone from 'vue2-dropzone';

import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/uz-UZ'
import './assets/css/main.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'
import './assets/css/tailwind.scss'
import './assets/css/custom-element-ui.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import ErrorMessage from "@/components/ui/ErrorMessage";
import Pagination from "@/components/ui/Pagination";
import Loader from "@/components/ui/Loader";

import VueTheMask from 'vue-the-mask';
import VueNumberFormat from '@coders-tm/vue-number-format'


Vue.use(Element, {locale});
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueNumberFormat, { precision: 4 })

// Vue.component('the-mask', TheMask)
Vue.component('vue-dropzone', vue2Dropzone);
Vue.component('error-message', ErrorMessage)
Vue.component('pagination', Pagination)
Vue.component('loader', Loader)

Vue.prototype.$mapBus = new Vue();
Vue.prototype.$sidebarBus = new Vue();
Vue.prototype.$drawerBus = new Vue();
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$axios = axiosHttp;

Vue.config.productionTip = false

store.dispatch('keycloak').then()