import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router =  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            components: {
                base: () => import('./layouts/baseLayout.vue')
            },
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('./views/dashboard/index.vue')
                }
            ]
        },
        {
            path: '/settings',
            components: {
                settings: () => import('./layouts/settingsLayout.vue')
            },
            children: [
                {
                    path: 'real-estate-error-fix',
                    name: 'settings-real-estate-error-fix',
                    component: () => import('./views/settings/real-estate-fix-error/RequestFixErrorRealEstateList.vue')
                },
                {
                    path: 'real-estate-error-fix/:id',
                    name: 'settings-real-estate-error-fix-view',
                    component: () => import('./views/settings/real-estate-fix-error/RequestFixErrorRealEstateView.vue')
                },
                {
                    path: 'cadastre-numbers',
                    name: 'settings-cadastre-numbers',
                    component: () => import('./views/settings/cadastre-number/CadastreNumbersList.vue')
                },
                {
                    path: 'cadastre-numbers/add',
                    name: 'settings-cadastre-numbers-add',
                    component: () => import('./views/settings/cadastre-number/CadastreNumbersAdd.vue')
                },
                {
                    path: 'category',
                    name: 'settings-category',
                    component: () => import('./views/settings/category/RealEstateCategoryList.vue')
                },
                {
                    path: 'category/edit/:id',
                    name: 'settings-category-edit',
                    component: () => import('./views/settings/category/RealEstateCategoryEdit.vue')
                },
                {
                    path: 'mfy',
                    name: 'settings-mfy',
                    component: () => import('./views/settings/mfy/MfyList.vue')
                },
                {
                    path: 'mfy/edit/:id',
                    name: 'settings-mfy-edit',
                    component: () => import('./views/settings/mfy/MfyAddEdit.vue')
                },
                // {
                //     path: 'real-estate-object',
                //     name: 'settings-create-estate-object',
                //     component: () => import('./views/settings/real-estate-object/RealEstateObjectList.vue')
                // },
                // {
                //     path: 'real-estate-object/edit/:id',
                //     name: 'settings-edit-estate-object',
                //     component: () => import('./views/settings/real-estate-object/RealEstateObjectDataAddEdit.vue')
                // },
                // {
                //     path: 'zone',
                //     name: 'settings-zone',
                //     component: () => import('./views/settings/zone/ZoneSettings.vue')
                // },
                // {
                //     path: 'massiv',
                //     name: 'settings-massiv',
                //     component: () => import('./views/settings/massiv/MassivSettings.vue')
                // },
                // {
                //     path: 'mavze',
                //     name: 'settings-mavze',
                //     component: () => import('./views/settings/mavze/MavzeSettings.vue')
                // },
                // {
                //     path: 'search-by-address',
                //     name: 'settings-search-by-address',
                //     component: () => import('./views/settings/search-by-address/SearchByAddress.vue')
                // },
                // {
                //     path: 'bind-mfy-to-home',
                //     name: 'settings-bind-mfy-to-home',
                //     component: () => import('./views/settings/bind-mfy-to-home/BindMFYIdToHomeList.vue')
                // },
                // WARNING! Please, put the route below only at the end
                {
                    path: '',
                    name: 'settings-index',
                    component: () => import('./views/settings/Settings.vue')
                }
            ]
        }
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});


router.beforeEach((to, from, next) => {
    return next();
});

export default router;