import Vue from 'vue';
import Vuex from 'vuex';
import {GLOBAL_LOADING} from "@/store/store";

export const SET_SETTINGS_ZONES = "SET_SETTINGS_ZONES";
export const SET_SAVED_SETTINGS_ZONES = "SET_SAVED_SETTINGS_ZONES";
export const SET_SETTINGS_MASSIV = "SET_SETTINGS_MASSIV";
export const SET_SAVED_SETTINGS_MASSIV = "SET_SAVED_SETTINGS_MASSIV";
export const SET_SETTINGS_MAVZE = "SET_SETTINGS_MAVZE";
export const SET_SAVED_SETTINGS_MAVZE = "SET_SAVED_SETTINGS_MAVZE";
export const GET_SAVED_SETTINGS_FROM_LOCAL_STORAGE = "GET_SAVED_SETTINGS_FROM_LOCAL_STORAGE";

Vue.use(Vuex);

const settingsStore = {
    state: {
        settingsZones: [],
        settingsMassiv: [],
        settingsMavze: [],
        savedSettingsZones: [],
        savedSettingsMassiv: [],
        savedSettingsMavze: []
    },
    mutations: {
        [SET_SETTINGS_ZONES](state, settingsZones) {
            state.settingsZones = settingsZones
        },
        [SET_SAVED_SETTINGS_ZONES](state, zoneId) {
            state.savedSettingsZones.push(zoneId)
            localStorage.setItem("SAVED_ZONES", JSON.stringify(state.savedSettingsZones))
        },
        [SET_SETTINGS_MASSIV](state, settingsMassiv) {
            state.settingsMassiv = settingsMassiv
        },
        [SET_SAVED_SETTINGS_MASSIV](state, massivId) {
            state.savedSettingsMassiv.push(massivId)
            localStorage.setItem("SAVED_MASSIV", JSON.stringify(state.savedSettingsMassiv))
        },
        [SET_SETTINGS_MAVZE](state, settingsMavze) {
            state.settingsMavze = settingsMavze
        },
        [SET_SAVED_SETTINGS_MAVZE](state, mavzeId) {
            state.savedSettingsMavze.push(mavzeId)
            localStorage.setItem("SAVED_MAVZE", JSON.stringify(state.savedSettingsMavze))
        },
        [GET_SAVED_SETTINGS_FROM_LOCAL_STORAGE](state) {
            state.savedSettingsZones = localStorage.getItem("SAVED_ZONES") ? JSON.parse(localStorage.getItem("SAVED_ZONES")) : []
            state.savedSettingsMassiv = localStorage.getItem("SAVED_MASSIV") ? JSON.parse(localStorage.getItem("SAVED_MASSIV")) : []
            state.savedSettingsMavze = localStorage.getItem("SAVED_MAVZE") ? JSON.parse(localStorage.getItem("SAVED_MAVZE")) : []
        }
    },
    actions: {
        getSettingsZones({commit}) {
            commit(GLOBAL_LOADING, true)
            Vue.prototype.$axios.get("zona/all")
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_SETTINGS_ZONES, res.data.data)
                    }
                    commit(GLOBAL_LOADING, false)
                }, err => {
                    console.log(err);
                    commit(GLOBAL_LOADING, false)
                });
        },
        resetSettingsZones({commit}) {
            commit(SET_SETTINGS_ZONES, [])
        },
        getSettingsMassiv({commit}) {
            commit(GLOBAL_LOADING, true)
            Vue.prototype.$axios.get("massiv/all")
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_SETTINGS_MASSIV, res.data.data)
                    }
                    commit(GLOBAL_LOADING, false)
                }, err => {
                    console.log(err);
                    commit(GLOBAL_LOADING, false)
                });
        },
        resetSettingsMassiv({commit}) {
            commit(SET_SETTINGS_MASSIV, [])
        },
        getSettingsMavze({commit}) {
            commit(GLOBAL_LOADING, true)
            Vue.prototype.$axios.get("mavze/all")
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_SETTINGS_MAVZE, res.data.data)
                    }
                    commit(GLOBAL_LOADING, false)
                }, err => {
                    console.log(err);
                    commit(GLOBAL_LOADING, false)
                });
        },
        resetSettingsMavze({commit}) {
            commit(SET_SETTINGS_MAVZE, [])
        },
        setSavedSettingsZoneId({commit}, zoneId) {
            commit(SET_SAVED_SETTINGS_ZONES, zoneId)
        },
        setSavedSettingsMassivId({commit}, massivId) {
            commit(SET_SAVED_SETTINGS_MASSIV, massivId)
        },
        setSavedSettingsMavzeId({commit}, mavzeId) {
            commit(SET_SAVED_SETTINGS_MAVZE, mavzeId)
        },
        getSavedSettingsDataFromLocalStorage({commit}) {
            commit(GET_SAVED_SETTINGS_FROM_LOCAL_STORAGE)
        }
    },
    getters: {

    }
};

export default settingsStore;