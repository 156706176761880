import Vue from 'vue';
import Vuex from 'vuex';
import {SET_MFY_POLYGON_DATA} from "@/store/items/mfy";
import {SET_PROVINCES} from "@/store/items/province";

export const SET_REGIONS = "SET_REGIONS";
export const SET_REGION = "SET_REGION";
export const SET_REGIONS_LOADING = "SET_REGIONS_LOADING";
export const SET_REGION_ID = "SET_REGION_ID";
export const SET_REGION_POLYGON_DATA = "SET_REGION_POLYGON_DATA";
export const SET_REGION_WITH_BORDER_LINE = "SET_REGION_WITH_BORDER_LINE";

Vue.use(Vuex);

const regionStore = {
    state: {
        regions: [],
        region: null,
        regionsLoading: false,
        regionPolygonData: [],
        withBorderLine: false,
        regionId: null
    },
    mutations: {
        [SET_REGIONS](state, regions) {
            state.regions = regions
        },
        [SET_REGION](state, region) {
            state.region = region
        },
        [SET_REGIONS_LOADING](state, regionsLoading) {
            state.regionsLoading = regionsLoading
        },
        [SET_REGION_ID](state, regionId) {
            state.regionId = regionId
        },
        [SET_REGION_POLYGON_DATA](state, regionPolygonData) {
            state.regionPolygonData = regionPolygonData
        },
        [SET_REGION_WITH_BORDER_LINE](state, withBorderLine) {
            state.withBorderLine = withBorderLine
        }
    },
    actions: {
        getRegions({commit, dispatch}, provinceId) {
            commit(SET_REGIONS_LOADING, true)
            dispatch("resetZones")
            dispatch("resetMFYData")
            Vue.prototype.$axios.get(`region/select/${provinceId}`)
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_REGIONS, res.data.data)
                        commit(SET_REGIONS_LOADING, false)
                    }
                }, err => {
                    console.log(err);
                    commit(SET_REGIONS_LOADING, false)
                });
            dispatch("getAllRegionPolygonsByProvinceId", provinceId)
        },
        setRegions({commit}, regions) {
            commit(SET_REGIONS, regions)
        },
        setRegionId({commit, dispatch, state}, {regionId, isModeSimple}) {
            commit(SET_REGION_ID, regionId)
            if(!isModeSimple) {
                if (regionId) {
                    if (state.withBorderLine){
                        dispatch("getPolygonByRegionIdWithBorderLine", regionId)
                    } else {
                        dispatch("getPolygonByRegionId", regionId)
                    }
                }
                else {
                    dispatch("resetMFYAllFields")
                    dispatch("resetRoadAllFields")
                    dispatch("resetZoneAllFields")
                    dispatch("resetMassivAllFields")
                }
            }
        },
        getPolygonByRegionId({commit, dispatch}, regionId) {
            Vue.prototype.$axios.get(`region/${regionId}`)
                .then(res => {
                    if (res && res.data && res.data.area) {
                        dispatch("getAllMfyPolygonsByRegionId", {regionId, regionData: [res.data.area]})
                    }
                    else {
                        commit(SET_REGION_POLYGON_DATA, [])
                    }
                }, err => {
                    console.log(err);
                });
        },
        getAllMfyPolygonsByRegionId({commit}, {regionId, regionData}) {
            Vue.prototype.$axios.get(`mfy/geom/all-polygons/${regionId}`)
                .then(res => {
                    if (res && res.data) {
                        commit(SET_MFY_POLYGON_DATA, res.data)
                    }
                    else {
                        commit(SET_MFY_POLYGON_DATA, [])
                    }
                    commit(SET_REGION_POLYGON_DATA, regionData)
                }, err => {
                    console.log(err);
                    commit(SET_REGION_POLYGON_DATA, regionData)
                });
        },
        getAllRegionPolygonsByProvinceId({commit}, provinceId) {
            Vue.prototype.$axios.get(`region/all-polygons/${provinceId}`)
                .then(res => {
                    if (res && res.data) {
                        commit(SET_REGION_POLYGON_DATA, res.data)
                    }
                    else {
                        commit(SET_REGION_POLYGON_DATA, [])
                    }
                }, err => {
                    console.log(err);
                });
        },
        resetRegions({commit}) {
            commit(SET_REGIONS, [])
        },
        resetRegionAllFields({commit}) {
            commit(SET_REGIONS, [])
            commit(SET_REGION, null)
            commit(SET_REGION_ID, null)
            commit(SET_REGION_POLYGON_DATA, [])
        },
        getRegionWithBorderline({commit, state, dispatch}, withBorderline) {
            commit(SET_REGION_WITH_BORDER_LINE, withBorderline)
            if (state.regionId && !state.mfyId){
                if (withBorderline){
                    dispatch("getPolygonByRegionIdWithBorderLine", state.regionId)
                } else {
                    dispatch("getPolygonByRegionId", state.regionId)
                }
            }
        },
        getPolygonByRegionIdWithBorderLine({state,commit, dispatch}, regionId) {
            Vue.prototype.$axios.get(`region/border-line/${regionId}`)
                .then(res => {
                    if (res && res.data) {
                        dispatch("getAllMfyPolygonsByRegionId", {regionId, regionData: res.data})
                    } else {
                        commit(SET_MFY_POLYGON_DATA, [])
                    }
                }, err => {
                    console.log(err);
                });
        }
    },
    getters: {
        regionsList: state => {
            return state.regions
        }
    }
};

export default regionStore;