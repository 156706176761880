<template>
  <div class="w-full h-full">
    <router-view name="base"></router-view>
    <router-view name="settings"></router-view>
    <GlobalLoaderIndicator/>
  </div>
</template>

<script>

import GlobalLoaderIndicator from "@/components/ui/GlobalLoaderIndicator.vue";
import {mapState} from "vuex";

export default {
  name: "Admin",
  components: {
    GlobalLoaderIndicator
  },
  computed: {
    ...mapState(["sidebar", "drawer"])
  }
}
</script>

<style scoped>

</style>